import linkedinlogo from "../linkedinlogo.png";

export const Home = () => {
  return (
    <div className="home-container">
      <div className="left-container">
        <div className="home-text">
          The <br />
          Big <br />
          Thing <br />
        </div>
      </div>
      <div className="right-container">
        <div className="mail-text">
          Contact us: <br />
          <a
            href="mailto:sencer.oztufekci@tbigt.tr"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            sencer.oztufekci@tbigt.tr
          </a>
          <p
            style={{
              display: "inline-block",
              paddingLeft: "0.3rem",
            }}
          >
            <a
              href="https://www.linkedin.com/in/sencer-oztufekci/"
              target="_blank"
            >
              <img src={linkedinlogo} className="linkedin-logo" />
            </a>
          </p>
          <br />
          <a
            href="mailto:merve.oztufekci@tbigt.tr"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            merve.oztufekci@tbigt.tr
          </a>
        </div>
      </div>
    </div>
  );
};
